import React from 'react';
import styles from './Disclaimer.module.css';
import { useSite } from '../context/SiteContext';
const Disclaimer = () => {
    const { siteName } = useSite();
  return (
    <div className={styles.disclaimerContainer}>
      <h3 className={styles.title}>Disclaimer</h3>
      <p className={styles.text}>
        {siteName} is a platform for having fun. The probability of winning playing this game cannot be considered the same as winning in real life games.
      </p>
      <p className={styles.text}>
        The game is available to adult audiences over 18 years of age.
      </p>
      <p className={styles.text}>
        Enjoy playing this game!
      </p>
    </div>
  );
};

export default Disclaimer;
