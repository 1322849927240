import React, { useState, useRef } from 'react';
import styles from './Sales.module.css';

import Slider from './Slider';

const Sales = () => {
   


    return (
        <div className={styles.container}>
       
        <Slider/>
       
        </div>
     
    );
};

export default Sales;
