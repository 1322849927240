import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import logo from '../assets/logo.png'; // Assume you have a logo.png in the assets folder

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.contactInfo}>
        <span className={styles.contactItem}>Phone Number: +61 123 456 789</span>
        <span className={styles.separator}>|</span>
        <span className={styles.contactItem}>Email: info@lux-casinoresorts.com</span>
        <span className={styles.separator}>|</span>
        <span className={styles.contactItem}>Main Office: 123 Casino Blvd, Sydney, NSW 2000</span>
      </div>
    </header>
  );
};

export default Header;
