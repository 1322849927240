import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => {
    return (
        <div className={styles.aboutUsContainer}>
            <h2>Lux Casino Resorts</h2>
            <p>
                Welcome to <strong>Lux Casino Resorts</strong> - your ultimate destination for luxury and excitement. Nestled in the heart of Australia's most stunning locations, our resorts offer a world-class experience combining opulence and adventure.
            </p>
            <p>
                At Lux Casino Resorts, we pride ourselves on providing unparalleled service and exquisite amenities. Whether you're indulging in our top-tier casino games, relaxing in our lavish spa, or dining at our gourmet restaurants, every moment is designed to exceed your expectations.
            </p>
            <p>
                Join our vibrant community and discover the advantages of staying at Lux Casino Resorts. With flexible packages and a welcoming atmosphere, we are your perfect getaway. Visit us at <b>lux-casinoresorts.com</b> to secure your stay and create unforgettable memories with us!
            </p>
          
        </div>
    );
};

export default AboutUs;
