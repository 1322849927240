import React from 'react';
import styles from './Reviews.module.css'; // Import the CSS Module

const Reviews = () => {
  const reviews = [
    { name: 'James', stars: 5, text: 'Lux Casino Resorts offers an unparalleled experience. The service was impeccable and the rooms were stunning.' },
    { name: 'Sophia', stars: 5, text: 'A truly luxurious getaway! The casino was thrilling and the amenities exceeded my expectations.' },
    { name: 'Liam', stars: 5, text: 'I had the best vacation at Lux Casino Resorts. The food was exquisite and the entertainment was top-notch.' },
    { name: 'Olivia', stars: 5, text: 'The staff at Lux Casino Resorts made us feel like royalty. Highly recommend for a relaxing and exciting stay.' },
    { name: 'Noah', stars: 5, text: 'From the moment we arrived, we were treated with the utmost care. The spa services were heavenly.' },
    { name: 'Emma', stars: 5, text: 'Lux Casino Resorts is the epitome of luxury. The views were breathtaking and the atmosphere was vibrant.' },
  ];

  return (
    <div className={styles.reviewsSection}>
      <h1 className={styles.reviewsHeading}>What Our Guests Say About Lux Casino Resorts</h1>
      <div className={styles.reviewsContainer}>
        {reviews.map((review, index) => (
          <div className={styles.reviewCard} key={index}>
            <div className={styles.reviewHeader}>
              <div>
                <h3 className={styles.reviewName}>{review.name}</h3>
                <div className={styles.reviewStars}>
                  {'⭐'.repeat(review.stars)}
                </div>
              </div>
            </div>
            <p className={styles.reviewText}>{review.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
