import React, { useState } from 'react';
import Modal from './Modal';
import styles from './SubscriptionForm.module.css';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && location) {
      setIsModalOpen(true);
      setEmail('');
      setLocation('');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.bookingForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3 className={styles.heading}>Book Your Hotel Room</h3>
        <input
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.emailInput}
          required
        />
        <select
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className={styles.locationSelect}
          required
        >
          <option value="" disabled>Select a location</option>
          <option value="Sydney">Sydney</option>
          <option value="Melbourne">Melbourne</option>
          <option value="Brisbane">Brisbane</option>
          <option value="Perth">Perth</option>
          <option value="Adelaide">Adelaide</option>
          <option value="Gold Coast">Gold Coast</option>
        </select>
        <button type="submit" className={styles.bookButton}>
          Book Now
        </button>
      </form>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2>Thank You!</h2>
        Thank you for booking with us! We look forward to welcoming you to {location}.
      </Modal>
    </div>
  );
};

export default SubscriptionForm;
