import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Assume there is a CSS module for styling

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>PRIVACY POLICY</h1>
      <p>
        At {siteName}, we prioritize your privacy and the security of your information. This document details our commitment to protecting your data and explains the practices you agree to by using our services and visiting our website at <strong>lux-casinoresorts.com</strong>.
      </p>

      <h2>1. DATA COLLECTION</h2>
      <p>
        <strong>Personal Information:</strong> We collect information such as your name, email address, phone number, and reservation details when you book a stay or service with us.
      </p>
      <p>
        <strong>Interaction Data:</strong> We use technologies like cookies to track your interactions with our website, storing information like your IP address and browser type to enhance your user experience.
      </p>

      <h2>2. USE OF INFORMATION</h2>
      <p>
        <strong>Reservation Management:</strong> Your personal information is used to manage your reservations, coordinate your stay, and maintain communication throughout your experience with us.
      </p>
      <p>
        <strong>Communication:</strong> We use your contact details to send booking confirmations, schedule updates, and important announcements related to our services.
      </p>
      <p>
        <strong>Personalization:</strong> With your consent, we tailor your website experience and offer personalized recommendations and services based on your previous interactions and preferences.
      </p>
      <p>
        <strong>Service Improvement:</strong> We analyze the data we collect to continually improve our website and services, ensuring a better experience for all guests.
      </p>

      <h2>3. DISCLOSURE OF INFORMATION</h2>
      <p>
        <strong>Business Operations:</strong> Your data may be shared with third-party service providers under strict confidentiality agreements to support operations like hospitality services and customer support.
      </p>
      <p>
        <strong>Legal Obligations:</strong> We may disclose your information when required by law to protect our rights, ensure the safety of our services, or safeguard public security.
      </p>

      <h2>4. SECURITY MEASURES</h2>
      <p>
        Our security protocols are designed to protect your personal information from unauthorized access and misuse. However, please be aware that online data transmission is not without risks.
      </p>

      <h2>5. YOUR PRIVACY PREFERENCES</h2>
      <p>
        <strong>Opting Out of Promotional Messages:</strong> You can opt out of receiving promotional messages at any time by using the links provided in our communications or by contacting us directly.
      </p>
      <p>
        <strong>Cookies:</strong> You can manage how interaction data is collected by adjusting your cookie preferences in your web browser settings.
      </p>

      <h2>6. CHILDREN'S SAFETY</h2>
      <p>
        We do not knowingly collect data from individuals under the age of 16. Any information inadvertently collected will be promptly deleted from our systems.
      </p>

      <h2>7. POLICY UPDATES</h2>
      <p>
        We periodically update our Privacy Policy to reflect new practices. We recommend reviewing the policy regularly, as continued use of our services after updates signifies acceptance of changes.
      </p>

      <p>
        If you have questions about our privacy practices or wish to obtain more information, please contact us at <a href={`mailto:info@lux-casinoresorts.com`}>info@lux-casinoresorts.com</a>. We are committed to responding promptly to your inquiries and safeguarding your privacy rights.
      </p>
    </div>
  );
};

export default Privacy;
