import React, { createContext, useContext } from 'react';

// Create a context for site information
const SiteContext = createContext();

// SiteProvider component to provide context to child components
export const SiteProvider = ({ children }) => {
  // Define site information for the luxury casino hotel business
  const siteInfo = {
    siteName: "lux-casinoresorts.com",
    slogan: "Where Luxury Meets Excitement",
    info: "Lux Casino Resorts: Experience Fortune and Elegance!",
    thankYouProduct: "Thank you for your interest in our offerings. We are delighted to be a part of your luxurious stay!",
    thankYouSubscription: "Thank you for subscribing to our newsletter! Stay tuned for exclusive offers and luxurious getaway ideas.",
    thankYouOrder: "Thank you for your booking. We look forward to creating unforgettable moments together!",
    locations: "We proudly offer luxurious experiences at our locations in Australia.",
  };

  // Provide site information to child components through SiteContext
  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Custom hook to use SiteContext in other components
export const useSite = () => {
  return useContext(SiteContext);
};
