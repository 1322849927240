import React from 'react';
import styles from './Home.module.css';
import Hero from '../components/Hero';
import Sales from '../components/Sales';
import AboutUs from '../components/AboutUs';
import Reviews from '../components/Reviews';

import Tariffs from '../components/Tariffs';
import Disclaimer from '../components/Disclaimer';
const Home = () => {
  return (<>
  <Hero/>
  
    <div id="locations-section" className={styles.container}>
      <Tariffs/>
    </div>
    <div id="aboutus-section" className={styles.container}>
    <AboutUs></AboutUs>
    </div>
      <div id="sales-section" className={styles.container}>
      <Sales></Sales>
      </div>
   
    <div id="reviews-section" className={styles.container}>
    <Reviews></Reviews>
    </div>
    <Disclaimer/>
    </>
  );
};

export default Home;
