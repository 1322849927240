import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Terms.module.css'; // Assuming you have a CSS module for styling

const Terms = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.termsInfo}>
      <h1>TERMS OF SERVICE</h1>
      <p>Welcome to {siteName}! By accessing and using our website at <strong>lux-casinoresorts.com</strong>, you agree to comply with the following terms and conditions. If you do not agree, please do not use our site.</p>
      
      <h2>1. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>All content on our website, including text, graphics, logos, icons, images, and videos, is owned by {siteName} and is protected by copyright and intellectual property laws. Unauthorized use or reproduction of our content is strictly prohibited.</p>
      
      <h2>2. PERMITTED USE</h2>
      <p>Our website is intended for personal use to explore our luxury casino and resort services. You may not modify, distribute, or reproduce any content without written permission. Activities that jeopardize the functionality or security of our site, such as harmful software, are prohibited.</p>
      
      <h2>3. INFORMATION ACCURACY</h2>
      <p>We strive to ensure that all information regarding our services, packages, and pricing is accurate. However, we cannot guarantee that all details are error-free. We reserve the right to correct any errors in service details, prices, and availability without prior notice.</p>
      
      <h2>4. PRICES AND PAYMENTS</h2>
      <p>Service prices are subject to change and are displayed in local currency. We offer secure payment options, and accepted payment methods are specified at the time of booking.</p>
      
      <h2>5. BOOKINGS AND CANCELLATIONS</h2>
      <p>Booking a stay or service at {siteName} constitutes an offer to engage our services, which we may accept or decline. Bookings and cancellations must adhere to the policies outlined on our website. Cancellation fees may apply if our policies are not followed.</p>
      
      <h2>6. USE OF PHOTOS AND VIDEOS</h2>
      <p>All photos and videos captured during your stay are for personal use. Commercial use requires explicit written permission from us.</p>
      
      <h2>7. EXTERNAL LINKS</h2>
      <p>Our website may contain links to external sites not operated by us. We are not responsible for the content or privacy practices of these sites, so exercise caution when accessing them.</p>
      
      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>{siteName} and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or reliance on the information provided. Our liability is limited to the maximum extent permitted by law.</p>
      
      <h2>9. ACCEPTANCE OF TERMS</h2>
      <p>These terms may be modified without notice. Continued use of our site following updates signifies acceptance of the revised terms. If you have any questions about these terms, please contact us at <a href={`mailto:info@lux-casinoresorts.com`}>info@lux-casinoresorts.com</a>.</p>
    </div>
  );
};

export default Terms;
