import React from 'react';
import styles from './Tariffs.module.css';
import hotel1 from '../assets/hotel1.webp'; // Import images
import hotel2 from '../assets/hotel2.webp';
import hotel3 from '../assets/hotel3.webp';
import hotel4 from '../assets/hotel4.webp';
import hotel5 from '../assets/hotel5.webp';
import hotel6 from '../assets/hotel6.webp';

const Tariffs = () => {
    const locations = [
        {
            name: "Sydney",
            description: "Experience the iconic Sydney Opera House and beautiful beaches.",
            address: "1 Casino Drive, Sydney, NSW 2000, Australia",
            image: hotel1
        },
        {
            name: "Melbourne",
            description: "Explore the vibrant arts scene and coffee culture.",
            address: "2 Gaming Lane, Melbourne, VIC 3000, Australia",
            image: hotel2
        },
        {
            name: "Brisbane",
            description: "Enjoy the warm climate and the Brisbane River.",
            address: "3 Entertainment Rd, Brisbane, QLD 4000, Australia",
            image: hotel3
        },
        {
            name: "Perth",
            description: "Discover stunning coastlines and unique wildlife.",
            address: "4 Ocean View Dr, Perth, WA 6000, Australia",
            image: hotel4
        },
        {
            name: "Adelaide",
            description: "Visit wineries and enjoy a relaxed atmosphere.",
            address: "5 Vineyard Ave, Adelaide, SA 5000, Australia",
            image: hotel5
        },
        {
            name: "Gold Coast",
            description: "Relax on the beaches and explore theme parks.",
            address: "6 Surfers Blvd, Gold Coast, QLD 4217, Australia",
            image: hotel6
        }
    ];

    return (
        <div className={styles.locationsContainer}>
            <h2>Our Locations</h2>
            <div className={styles.locationList}>
                {locations.map((location, index) => (
                    <div key={index} className={styles.locationItem}>
                        <img src={location.image} alt={location.name} className={styles.locationImage} />
                        <h3>{location.name}</h3>
                        <p>{location.description}</p>
                        <p className={styles.address}>{location.address}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tariffs;
